import {
  Card,
  FormHelperText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getCaseById } from "../../../services/casesService";
import { useParams } from "react-router-dom";
import { getNewServicesByProgramId } from "../../../services/newServicesService";
import {
  deleteFamilyServiceLog,
  getCommunityResources,
  getFamilyServiceLog,
  getFamilyServiceLogCount,
  postFamilyServiceLog,
  updateFamilyServiceLog,
} from "../../../services/familyServiceLogService";
import { getProvidedServicesByProgramIdAndFiscalYear } from "../../../services/providedServicesService";
import { getProgramByFiscalYearById } from "../../../services/getProgramService";

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

const FamilyServiceLog = ({ fiscalYear }) => {
  const { id: caseId } = useParams();
  const [data, setData] = useState("");
  const [fslCount, setFslCount] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [fsl, setFsl] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [services, setServices] = useState([]);
  const [children, setChildren] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [communityResources, setCommunityResources] = useState([]);
  const [validated, setValidated] = useState(false);
  const [key, setKey] = useState(0); //HB: We want to make sure that these temporary keys never have the same value as a service's actual id, so negative numbers work well.
  const [existingServices, setExistingServices] = useState([]);
  const [remainingUnits, setRemainingUnits] = useState("");
  const [unitsError, setUnitsError] = useState("");
  const [recipientsError, setRecipientsError] = useState("");

  const classes = useStyles2();
  const programId = sessionStorage.getItem("ProgramId");
  const reportingPeriod = sessionStorage.getItem("ReportingPeriod");
  const isAdmin = window.location.pathname.includes("admin");

  const SubmitUrl = isAdmin
    ? "/admin/opencases"
    : "/provider/currentfy/opencases";

  const FOR_SERVICE = 0;
  const FOR_FSL = 2;

  async function fetchData() {
    if (fiscalYear.value) {
      const { data: _case } = await getCaseById(caseId);
      setChildren(_case.children);
      setCaregivers(_case.caregivers);
      const { data: services } = await getNewServicesByProgramId(
        programId,
        fiscalYear.value
      ); //HB: fiscalYear is not working for some reason, returns empty string instead of 2022. I don't have any more time to put into this bug so it needs refactoring later

      const sortedServices = services.sort((a, b) => {
        if (a.codeSub > b.codeSub) return 1;
        if (a.codeSub < b.codeSub) return -1;
        return 0;
      });

      setServices(sortedServices);

      const { data: communityResources } = await getCommunityResources();
      setCommunityResources(communityResources);

      const { data: existingServices } =
        await getProvidedServicesByProgramIdAndFiscalYear(
          programId,
          fiscalYear.value
        );
      const sortedExistingServices = existingServices.sort((a, b) => {
        if (a.codeSub > b.codeSub) return 1;
        if (a.codeSub < b.codeSub) return -1;
        return 0;
      });

      setExistingServices(sortedExistingServices);
      setLoading(false);
    }
  }

  /*
    HB: I made this a separate function from fetchData because I only ever need this to run one time: when the page first loads. I do not want to refresh this when we save
    a service as we do with fetchData()
  */
  async function checkForExistingFsl() {
    const { data: fsl } = await getFamilyServiceLog(
      caseId,
      parseInt(reportingPeriod)
    );

    fsl && handleExistingServices(fsl);
    setFsl(fsl);

    const { data: fslCount } = await getFamilyServiceLogCount(
      caseId,
      parseInt(reportingPeriod),
      14143
    );
    setFslCount(fslCount);
  }

  function handleRemainingUnits(newServicesId) {
    if (existingServices.length < 1) {
      //Set remaining untis to max units
      setRemainingUnits(
        services.find((x) => x.id === parseInt(newServicesId)).unitsPerYear
      );
      return;
    }

    var existingServicesByCode = existingServices.filter((service) => {
      return service.newServicesId === parseInt(newServicesId);
    });

    if (existingServicesByCode === null || existingServicesByCode.length < 1) {
      //Set remaining untis to max units
      setRemainingUnits(
        services.find((x) => x.id === parseInt(newServicesId)).unitsPerYear
      );
      return;
    }

    var spentUnits = 0;
    existingServicesByCode.map(
      (service) => (spentUnits = spentUnits + service.units)
    );

    //HB: unitsPerYear will be the same for every entry in existingServicesByCode, so I can just take the first objecy tin the array for comparison
    var remainingUnits = existingServicesByCode[0].unitsPerYear - spentUnits;
    setRemainingUnits(remainingUnits);
  }

  const handleExistingServices = (fsl) => {
    let tempKey = key;
    let newFsl = { ...fsl };
    let newFslCommRec = newFsl.fslCommunityResources;
    newFsl.fslCommunityResources = [];
    newFslCommRec.map((commRec) => {
      return newFsl.fslCommunityResources.push(commRec.communityResourceId);
    });

    newFsl.providedServices.map((service) => {
      return (service.key = tempKey++);
    });
    setKey(tempKey + 1);
    let sort = newFsl.providedServices;

    sort.forEach((val) => {
      val["sortColumn"] = val.newService.newServiceCode.codeSub;
    });

    sort.sort((a, b) => {
      return a.sortColumn - b.sortColumn;
    });

    setData(newFsl);
  };

  const handleChange = (event, type) => {
    const name = event.target.id;
    if (name === "units" && event.target.value < 0) return;
    if (type === 0) {
      let newData = { ...serviceData };
      newData[name] = event.target.value;
      setServiceData(newData);
      if (name === "newServicesId") handleRemainingUnits(event.target.value);
    } else if (type === 2) {
      let newData = { ...data };
      newData[name] = event.target.value;

      setData(newData);
    }
  };

  const handleCheck = (event) => {
    const name = event.target.name;
    const newData = { ...data };
    newData[name] = !newData[name];
    setData(newData);
  };

  const saveService = () => {
    let newData = { ...data };

    if (serviceData.units === 0 || serviceData.units < 1) {
      setUnitsError("floatingUnits");
      return false;
    }

    if (
      !serviceData.childServiceRecipients &&
      !serviceData.caregiverServiceRecipients
    ) {
      setRecipientsError("noRecipients");
      return;
    }
    if (serviceData.units > remainingUnits) {
      setUnitsError("exceededRemainingUnits");
      return;
    }

    if (
      serviceData.newServicesId &&
      services.find((x) => x.id === parseInt(serviceData.newServicesId)) &&
      services.find((x) => x.id === parseInt(serviceData.newServicesId))
        .oneToOne &&
      parseInt(serviceData.units) !== parseFloat(serviceData.units)
    ) {
      setUnitsError("floatingUnits");
      return;
    }

    if (!newData.providedServices) newData.providedServices = [];

    serviceData["key"] = key;

    var service = services.find(
      (service) => service.id === parseInt(serviceData.newServicesId)
    );

    if (!service) {
      service = service["key"] = key + 1;
    }

    service["key"] = key + 1;

    service["newServicesId"] = serviceData.newServicesId;

    service["units"] = serviceData.units;

    service["sortColumn"] = service.codeSub;

    setKey(key + 1);

    newData.providedServices.push(service);

    let sort = newData.providedServices;

    sort.forEach((val) => {
      val["sortColumn"] =
        val?.newService?.newServiceCode.codeSub || val.codeSub;
    });

    sort.sort((a, b) => {
      return a.sortColumn - b.sortColumn;
    });

    setData(newData);
    setServiceData([]);
    setUnitsError("");
    setRecipientsError("");
    fetchData();
    // Handle Auto Save From Change to Manage Used Calculations
    handleAutoSave(caseId, reportingPeriod, newData);
    // End Resort on loading
  };

  const handleMultiCheck = (event, type) => {
    const id = parseInt(event.target.id);
    const name = event.target.name;
    let newServiceData = { ...serviceData };

    if (!newServiceData[name]) newServiceData[name] = [];

    var index = newServiceData[name].indexOf(id);

    index > -1
      ? newServiceData[name].splice(index, 1)
      : newServiceData[name].push(id);
    setServiceData(newServiceData);
  };

  const handleCRMultiCheck = (event, type) => {
    const id = parseInt(event.target.id);
    const name = event.target.name;
    let newData = { ...data };

    if (!newData[name]) newData[name] = [];

    var index = newData[name].indexOf(id);

    index > -1 ? newData[name].splice(index, 1) : newData[name].push(id);

    setData(newData);
  };

  const handleDelete = (key, Id) => {
    deleteFamilyServiceLog(Id);
    let newData = { ...data };
    newData.providedServices = [];
    data.providedServices.map(
      (service) => service.key !== key && newData.providedServices.push(service)
    );
    setData(newData);
    fetchData();
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (fsl) {
        await updateFamilyServiceLog(caseId, reportingPeriod, data);
      } else {
        await postFamilyServiceLog(caseId, reportingPeriod, data);
      }
    }
    setValidated(true);
    return false;
  };

  const paperStyle = {
    padding: 20,
    height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };

  const cardStyle = {
    padding: 20,
    height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
  };

  async function handleAutoSave(caseId, reportingPeriod, data) {
    try {
      if (fsl) {
        await updateFamilyServiceLog(caseId, reportingPeriod, data);
      } else {
        await postFamilyServiceLog(caseId, reportingPeriod, data);
      }
    } catch (error) {
      console.error("Error Saving Changes...", error);
      console.warn("Error Saving Changes...", error);
    }
    checkForExistingFsl();
  }

  async function checkIfReportsIsLocked() {
    const { data: program } = await getProgramByFiscalYearById(programId);

    if (program?.program?.doneLockStatus === parseInt(reportingPeriod)) {
      // Security Feature: This Program Is Locked ReDirect Back To OpenLogs
      window.location.replace("/provider/currentfy/opencases");
    }
  }

  useEffect(() => {
    checkIfReportsIsLocked();
    fetchData();
    checkForExistingFsl();
  }, [caseId, fiscalYear]);

  if (Loading) {
    return (
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          marginTop: 150,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack spacing={2} direction="row">
          <CircularProgress />
        </Stack>
      </div>
    );
  }

  return (
    <Paper elevation={10} style={paperStyle}>
      <h1>Family Service Log</h1>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Service Code</TableCell>
              <TableCell align="center">Letter Code</TableCell>
              <TableCell align="center">Code Description</TableCell>
              <TableCell align="center">Units</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.providedServices &&
              data.providedServices
                //.sort()
                //.sort((a, b) => a.newServicesId > b.newServicesId ? 1 : -1)
                .map((row) => {
                  var service = services.find(
                    (service) => service.id === parseInt(row.newServicesId)
                  );

                  var count = fslCount.find(
                    (count) =>
                      count.codeSub === row?.newService?.newServiceCode?.codeSub
                  );

                  return (
                    <TableRow key={row.key}>
                      <TableCell align="center">
                        {service && service?.codeSub}
                      </TableCell>
                      <TableCell align="center" title="Program & Letter Code:">
                        {service?.programCode || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {service && service.description} -{" "}
                        {service && service.subDescription}
                      </TableCell>
                      <TableCell align="center">
                        {service?.oneToOne ? count?.units ?? row.units : "-"}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="danger"
                          onClick={() => handleDelete(row.key, row.id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        action={SubmitUrl}
      >
        <div className="py-4 row">
          <div className="col-lg-6">
            <Card style={cardStyle}>
              <h4>Report Individual/Family Services</h4>
              <div className="py-2">
                <Form.Group controlId="newServicesId">
                  <Form.Label>Service Code</Form.Label>
                  <Form.Select
                    onChange={(event) => handleChange(event, FOR_SERVICE)}
                    defaultValue={serviceData.newServicesId}
                  >
                    <option>Open this select menu</option>
                    {services.length > 1 &&
                      services.map(
                        (option) =>
                          (!data.providedServices ||
                            (data.providedServices &&
                              !data.providedServices.find(
                                (x) => parseInt(x.newServicesId) === option.id
                              ))) && (
                            <option key={option.id} value={option.id}>
                              {option.codeSub} - {option.description} -{" "}
                              {option.subDescription} {option.programCodeDesc}{" "}
                              {option.programCode}
                            </option>
                          )
                      )}
                  </Form.Select>
                </Form.Group>
              </div>

              {serviceData.newServicesId &&
                services.find(
                  (x) => x.id === parseInt(serviceData.newServicesId)
                ) &&
                services.find(
                  (x) => x.id === parseInt(serviceData.newServicesId)
                ).oneToOne && (
                  <div className="row">
                    {unitsError === "exceededRemainingUnits" && (
                      <FormHelperText className="text-danger">
                        You have entered more units than are available for the
                        remainder of the year.
                      </FormHelperText>
                    )}
                    {unitsError === "floatingUnits" && (
                      <FormHelperText className="text-danger">
                        Enter a whole number for units.
                      </FormHelperText>
                    )}
                    <div className="col-md-6">
                      <Form.Group className="col-lg-6 mb-2" controlId="units">
                        <Form.Label>Units:</Form.Label>
                        <Form.Control
                          type="number"
                          className="form-control-sm"
                          value={serviceData.units}
                          max={remainingUnits}
                          min={0}
                          onChange={(event) => {
                            const value = event.target.value;
                            if (value > remainingUnits) {
                              event.target.value = remainingUnits;
                            } else {
                              handleChange(event, FOR_SERVICE);
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                    {serviceData.newServicesId && (
                      <div className="col-md-6">
                        <Form.Label>Remaining Available Units</Form.Label>
                        <h5 className="text-success">
                          {remainingUnits - (serviceData.units || 0)}
                        </h5>
                      </div>
                    )}
                  </div>
                )}

              <div className="row">
                {recipientsError && (
                  <FormHelperText className="text-danger">
                    Please select at least one recipient.
                  </FormHelperText>
                )}
                <div className="col-md-6 py-2">
                  <Form.Group>
                    <Form.Label>Child Recipient(s)</Form.Label>
                    {children.length > 0 &&
                      children.map((child) => (
                        <div key={child.id}>
                          <Form.Check
                            type={"checkbox"}
                            id={child.id}
                            name={"childServiceRecipients"}
                            label={`${child.firstName} ${child.lastName}`}
                            checked={
                              (serviceData.childServiceRecipients &&
                                serviceData.childServiceRecipients.length > 0 &&
                                serviceData.childServiceRecipients.indexOf(
                                  parseInt(child.id)
                                ) > -1) ||
                              false
                            }
                            onChange={(event) =>
                              handleMultiCheck(event, FOR_SERVICE)
                            }
                          />
                        </div>
                      ))}
                  </Form.Group>
                </div>

                <div className="col-md-6 py-2">
                  <Form.Group>
                    <Form.Label>Caregiver Recipient(s)</Form.Label>
                    {caregivers.length > 0 &&
                      caregivers.map((caregiver) => (
                        <div key={caregiver.id}>
                          <Form.Check
                            type={"checkbox"}
                            id={caregiver.id}
                            name={"caregiverServiceRecipients"}
                            label={`${caregiver.firstName} ${caregiver.lastName}`}
                            checked={
                              (serviceData.caregiverServiceRecipients &&
                                serviceData.caregiverServiceRecipients.length >
                                  0 &&
                                serviceData.caregiverServiceRecipients.indexOf(
                                  parseInt(caregiver.id)
                                ) > -1) ||
                              false
                            }
                            onChange={(event) =>
                              handleMultiCheck(event, FOR_SERVICE)
                            }
                          />
                        </div>
                      ))}
                  </Form.Group>
                </div>
              </div>
              <button
                type="button"
                disabled={
                  remainingUnits - (serviceData.units || 0) < 0 ||
                  serviceData.units <= 0
                    ? true
                    : false
                }
                className={
                  remainingUnits - (serviceData.units || 0) < 0
                    ? "btn btn-danger"
                    : "btn btn-outline-primary"
                }
                onClick={saveService}
              >
                Add Service
              </button>
            </Card>
          </div>

          <div className="col-lg-6">
            <Card style={cardStyle}>
              <Form.Group className="row px-4" controlId="noServicesProvided">
                <Form.Check
                  type={"checkbox"}
                  id="noServicesProvided"
                  required={
                    !data.providedServices ||
                    (data.providedServices && data.providedServices.length < 1)
                  }
                  name="noServicesProvided"
                  label="No services provided."
                  onChange={handleCheck}
                  checked={data.noServicesProvided || false}
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="notes">
                <Form.Label>FSL Notes:</Form.Label>
                <Form.Control
                  as="textarea"
                  required={data.noServicesProvided}
                  className="form-control-sm"
                  defaultValue={data.notes}
                  onChange={(event) => handleChange(event, FOR_FSL)}
                />
              </Form.Group>
            </Card>
          </div>
        </div>
        <div className="row px-2">
          <Card style={cardStyle}>
            <Form.Label>
              Identify any referrals or community resources identified and
              accessed by the family during the month of [insert month of period
              reporting]:
            </Form.Label>
            <div className="row">
              {communityResources &&
                communityResources.map((item) => (
                  <div key={item.id} className="col-lg-4">
                    <Form.Check
                      type={"checkbox"}
                      id={item.id}
                      name={"fslCommunityResources"}
                      label={item.description}
                      onChange={(event) => handleCRMultiCheck(event, FOR_FSL)}
                      checked={
                        (data.fslCommunityResources &&
                          data.fslCommunityResources.indexOf(item.id) > -1) ||
                        false
                      }
                    />
                    {/* {item.id === 26 && data.fslCommunityResources && data.fslCommunityResources.indexOf(26) > -1 &&
                  <Form.Group className="col-lg-6 mb-2" controlId="lifeSkillsResource">
                    <Form.Label>Enter Life Skills</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control-sm"
                      onChange={(event) => handleChange(event, FOR_FSL)}
                      defaultValue={data.lifeSkillsResource}
                    />
                  </Form.Group>
                  } */}
                    {item.id === 36 &&
                      data.fslCommunityResources &&
                      data.fslCommunityResources.indexOf(36) > -1 && (
                        <Form.Group
                          className="col-lg-6 mb-2"
                          controlId="otherResource"
                        >
                          <Form.Label>Enter Other Resource</Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control-sm"
                            onChange={(event) => handleChange(event, FOR_FSL)}
                            defaultValue={data.otherResource}
                          />
                        </Form.Group>
                      )}
                  </div>
                ))}
            </div>
          </Card>
        </div>
        <Card style={cardStyle}>
          <Button className="float-end" type="submit">
            Submit Log
          </Button>
        </Card>
      </Form>
    </Paper>
  );
};

export default FamilyServiceLog;

/*
<div className="col-lg-6">
          <Card style={cardStyle}>
            <h4>Report Group Services</h4>
            <div className="py-2">
              <Form.Group controlId="serviceCode">
                <Form.Label>Service Code</Form.Label>
                <Form.Select
                  onChange={(event) => handleChange(event, FOR_GROUP)}
                  defaultValue={groupData.serviceCode}
                >
                  <option>Open this select menu</option>
                  {services.length > 1 &&
                    services.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.codeSub}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="py-2">
              <Form.Group controlId="childId">
                <Form.Label>Child Recipient(s)</Form.Label>
                <Form.Select
                  onChange={(event) => handleChange(event, FOR_GROUP)}
                  multiple aria-label="multiple select"
                  //defaultValue={groupData.serviceCode}
                >
                  {children.length > 1 &&
                    children.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.firstName}&nbsp;{option.lastName}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="py-2">
              <Form.Group controlId="caregiverId">
                <Form.Label>Caregiver Recipient(s)</Form.Label>
                <Form.Select
                  onChange={(event) => handleChange(event, FOR_GROUP)}
                  multiple aria-label="multiple select"
                  //defaultValue={groupData.serviceCode}
                >
                  {caregivers.length > 1 &&
                    caregivers.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.firstName}&nbsp;{option.lastName}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </div>
            <Button onClick={saveGroup}>Save</Button>
          </Card>
        </div>
*/
